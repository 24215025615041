import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/Step_two.svg"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    step="2"
    title="Crează un cont nou sau folosește contul existent"
    firstRadioLink="/salut/stepThree"
    secondRadioLink="/salut/locDeConsumQuest"
    firstRadioText="Nu am cont MyAra"
    secondRadioText="Am deja un cont MyAra"
    buttonLink="/salut/stepOne"
  ></TutorialLayout>
)

export default Second
